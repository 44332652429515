<template>
  <v-row :style="'width:' + maxWidth + 'px'" style="padding:0;margin:0;position:sticky">
    <v-col class="scheduleColumn leftSticky" :class="thin ? 'thin' : ''" style="padding:0;margin:0">
      <v-card :style="'width:' + (collapsed ? 120 : 300) + 'px'">
        <v-card-text>{{ collapsed ? shortText : longText }}</v-card-text>
      </v-card>
    </v-col>
    <v-col v-for="time in times" :key="time" class="scheduleColumn">
      <div :style="'width:' + (cellWidth - 2) + 'px'"></div>
    </v-col>
    <schedule-event
      v-for="(event, index) in events"
      :key="'event-' + index"
      :event="event"
      :start-hour="startHour"
      :end-hour="endHour"
      :cell-width="cellWidth"
      :half="half"
      :thin="thin"
      @click="$emit('click', event._id)"></schedule-event>
  </v-row>
</template>
<script>
import { computed } from '@vue/composition-api'
export default {
  components: {
    ScheduleEvent: () => import('./scheduleEvent')
  },
  props: {
    shortText: {
      type: String,
      default: '**NP**'
    },
    longText: {
      type: String,
      default: '**Not Provided**'
    },
    times: {
      type: Array,
      required: true
    },
    events: {
      type: Array,
      required: true
    },
    cellWidth: {
      type: Number,
      default: 100
    },
    half: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root }) {
    const maxEnd = computed(() => props.cellWidth * props.times.length)
    const maxWidth = computed(() => (collapsed.value ? 122 : 302) + maxEnd.value)

    const collapsed = computed({
      get: () => root.$store.state.calendar.collapsed,
      set: (val) => root.$store.commit('calendar/setCollapsed', val)
    })
    const thin = computed({
      get: () => root.$store.state.calendar.thin,
      set: (val) => root.$store.commit('calendar/setThin', val)
    })
    const startHour = computed(() => root.$store.state.calendar.startHour)
    const endHour = computed(() => root.$store.state.calendar.endHour)

    return {
      maxEnd,
      maxWidth,
      collapsed,
      thin,
      startHour,
      endHour
    }
  }
}
</script>
